import { notifyError, notifySuccess } from "~/utils/notifications.js";
import { storeToRefs } from "pinia";
import { activitiesApiService } from "~/services/activitiesApiService";

export const useActivitiesApi = () => {
  const data = ref(null);
  const error = ref(null);
  const loading = ref(false);

  const { fetchActivities } = activitiesApiService();

  const fetchActivitiesApi = async ({
    start,
    end,
    limit = null,
    shouldFormat = false,
  }) => {
    loading.value = true;
    data.value = null;

    const params = {
      period: JSON.stringify({
        startDatetime: String(start),
        endDatetime: String(end),
      }),
      limit: limit,
    };

    try {
      const response = await fetchActivities(params);
      const message =
        response.data.data?.message || "An unknown error occurred.";
      if (response?.status === 200) {
        data.value = response.data?.data;
        if (shouldFormat) groupAndFormatActivities(response.data?.data);
      } else {
        notifyError({
          title: response.data?.message || "An unknown error occurred.",
        });
      }
    } catch (err) {
      console.error(err);
      error.value = err?.message;
      notifyError();
    } finally {
      loading.value = false;
    }
  };

  const groupAndFormatActivities = () => {
    data.value = data.value.reduce((activity, item) => {
      const activityGroup = item.group_name;
      activity[activityGroup] = activity[activityGroup] || [];
      activity[activityGroup].push(item);
      return activity;
    }, {});

    addColorsAndIcon();
  };

  const addColorsAndIcon = () => {
    for (var i in data.value) {
      for (let j of data.value[i]) {
        if (j.action.includes("Unable")) {
          j["icon"] = "pi pi-exclamation-circle";
          j["bgColor"] = "bg-red-600";
          j["iconColor"] = "text-red-600";
          j["borderColor"] = "border-red-600";
        } else if (j.flag == "addition") {
          j["icon"] = "pi pi-plus";
          j["bgColor"] = "bg-green-300";
          j["iconColor"] = "text-green-600";
          j["borderColor"] = "border-green-300";
        } else if (j.flag == "change") {
          j["icon"] = "pi pi-pencil";
          j["bgColor"] = "bg-blue-300";
          j["iconColor"] = "text-blue-700";
          j["borderColor"] = "border-blue-300";
        } else if (j.flag == "deletion") {
          j["icon"] = "pi pi-minus";
          j["bgColor"] = "bg-red-400";
          j["iconColor"] = "text-red-700";
          j["borderColor"] = "border-red-400";
        }
      }
    }
  };

  return {
    data,
    error,
    loading,
    fetchActivitiesApi,
  };
};
