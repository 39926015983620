import { useAuthStore } from "~/store/auth.js";
import { storeToRefs } from "pinia";

export const activitiesApiService = () => {
  if (process.server) return;

  // Initialize your variables and functions here
  const apis = new APIS();
  const BASE_URL = apis.BASE_URL;
  const header = storeToRefs(useAuthStore()).header;

  const { sendGetRequest } = useSendRequest();

  const getConfig = (params = {}) => ({
    headers: header.value,
    responseType: "json",
    params: params,
  });

  const fetchActivities = async (params) => {
    try {
      const url = `${BASE_URL + apis.ACTIVITY_LOGS}`;
      const response = await sendGetRequest(url, getConfig(params));
      return response;
    } catch (error) {
      console.log(error);
      throw new Error(error.response?.data?.message || "API request failed");
    }
  };

  return {
    fetchActivities,
  };
};
